const Contact = () => {
    return (
        <div className="absolute top-20">
            <h1>
                This is contact us page!!
            </h1>
        </div>
    )
}

export default Contact;